import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UpdateJobPostStatusResponse } from '../../models/ThunksInterfaces/retrieveJobPosts';
import { changeCompany } from '../../store/auth/actions';
import { EDIT_RECRUITMENT } from '../../store/editRecruitment/thunk';
import { UPDATE_JOB_POST_STATUS } from '../../store/jobs/thunk';
import {
  APPLICATIONS_SLICE,
  resetNotInvitedBySms,
  resetSelectedApplication,
  resetSelectedJob,
  selectApplicationCustomQuestionnaire,
  selectApplicationQuestion,
  selectApplicationSkill,
} from './actions';
import { ApplicationStatusDbModel } from './interfaces/applicationStatusDb.model';
import { InviteCandidateToVideoInterview } from './interfaces/inviteCandidateToVideoInteview';
import { NotInvitedBySms } from './interfaces/notInvitedBySms';
import { RetrieveApplication } from './interfaces/retrieveApplication';
import { RetrieveApplications } from './interfaces/retrieveApplications';
import { RetrieveJobPostStates } from './interfaces/retrieveJobPostStates';
import { SubmitApplicationRating } from './interfaces/submitApplicationRating';
import { SubmitCvRating } from './interfaces/submitCvRating';
import { SubmitCvUpload } from './interfaces/submitCvUpload';
import { SubmitEmailFeedbackResponse } from './interfaces/submitEmailFeedback';
import { SubmitPresentationRatingResponse } from './interfaces/submitPresentationRating';
import {
  BULK_APPLICATIONS_PHONE_UPDATE,
  BULK_EMAIL_INVITE_TO_VIDEOINTERVIEW,
  BULK_SMS_INVITE_TO_VIDEOINTERVIEW,
  DELETE_APPLICATION,
  DELETE_APPLICATION_RATING,
  DELETE_APPLICATION_STATE,
  DOWNLOAD_CANDIDATE_REPORT_LIGHT,
  DOWNLOAD_CANDIDATE_REPORT_PRO,
  EXPORT_CANDIDATES_TO_EXCEL,
  REQUEST_CANDIDATE_REPORT,
  RETRIEVE_APPLICATION,
  RETRIEVE_JOBPOST_APPLICATIONS,
  RETRIEVE_JOB_POST_STATES,
  SINGLE_EMAIL_INVITE_CANDIDATE_TO_VIDEOINTERVIEW,
  SINGLE_SMS_INVITE_CANDIDATE_TO_VIDEOINTERVIEW,
  SUBMIT_APPLICATION_RATING,
  SUBMIT_APPLICATION_STATE,
  SUBMIT_CV_RATING,
  SUBMIT_CV_UPLOAD,
  SUBMIT_EMAIL_FEEDBACK,
  SUBMIT_PRESENTATION_RATING,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_STATE,
  UPDATE_APPLICATION_STATUS,
} from './thunk';

export interface ApplicationsState {
  // Flag for pending retrieve applications list request
  loading: boolean;
  // Flag for pending candidate report light
  downloadReportLightLoading: boolean;
  // Flag for pending candidate report pro
  downloadReportProLoading: boolean;
  // Flag for pending retrieve single application request
  applicationLoading: boolean;
  // Flag for pending excel export
  exportCandidatesLoading: boolean;
  // The job post selected by the user
  selectedJob: RetrieveApplications.Job;
  // Available applications for the selected job post
  applications: RetrieveApplications.Application[];
  // Total of applications
  totalApplications: number;
  // Application status counters (ie: "candidates: 2", "custom: 3")
  applicationStatusCounter: RetrieveApplications.ApplicationStatusCounter;
  // The application selection by the user (opened in the right panel)
  selectedApplication: RetrieveApplication.Response;
  // Available states for the selected job post
  states: ApplicationStatusDbModel[];
  // The skill selected from the list when the user is viewing the application
  selectedApplicationSkill: RetrieveApplication.Skill;
  // Selected question
  selectedQuestion: RetrieveApplication.Question;
  // Selected custom questionnaire
  selectedCustomQuestionnaire: RetrieveApplication.CustomQuestionnaire | null;
  // Applications not invited by SMS after bulk SMS invite
  notInvitedBySms: NotInvitedBySms | null;
}

const ApplicationInitialState: ApplicationsState = {
  loading: false,
  downloadReportLightLoading: false,
  downloadReportProLoading: false,
  applicationLoading: false,
  exportCandidatesLoading: false,
  selectedJob: {} as RetrieveApplications.Job,
  applications: [],
  totalApplications: 0,
  applicationStatusCounter: {
    applied: 0,
    invited: 0,
    scored: 0,
  },
  selectedApplication: {} as RetrieveApplication.Response,
  states: [],
  selectedApplicationSkill: {} as RetrieveApplication.Skill,
  selectedQuestion: {} as RetrieveApplication.Question,
  selectedCustomQuestionnaire: null,
  notInvitedBySms: null,
};

const slice = createSlice({
  name: APPLICATIONS_SLICE,
  initialState: ApplicationInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(RETRIEVE_JOBPOST_APPLICATIONS.pending, (state, _) => ({
        ...state,
        loading: true,
        applications: [],
      }))
      .addCase(
        RETRIEVE_JOBPOST_APPLICATIONS.fulfilled,
        (state, action: PayloadAction<RetrieveApplications.Response>) => ({
          ...state,
          loading: false,
          selectedJob: action.payload.job,
          applications: action.payload.applications,
          applicationStatusCounter: action.payload.counters,
          totalApplications: Object.keys(action.payload.counters).reduce(
            (acc, value) => acc + action.payload.counters[value],
            0
          ),
        })
      )
      .addCase(RETRIEVE_JOBPOST_APPLICATIONS.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(RETRIEVE_APPLICATION.pending, (state, _) => ({
        ...state,
        applicationLoading: true,
      }))
      .addCase(
        RETRIEVE_APPLICATION.fulfilled,
        (state, action: PayloadAction<RetrieveApplication.Response>) => ({
          ...state,
          applicationLoading: false,
          selectedApplication: action.payload,
        })
      )
      .addCase(RETRIEVE_APPLICATION.rejected, (state, _) => ({
        ...state,
        applicationLoading: false,
      }))
      .addCase(UPDATE_APPLICATION_STATUS.pending, (state, _) => ({
        ...state,
        loading: true,
        applicationLoading: true,
      }))
      .addCase(
        UPDATE_APPLICATION_STATUS.fulfilled,
        (state, action: PayloadAction<RetrieveApplication.Response>) => {
          const currentStatusKey: string | undefined = state.selectedApplication?.status.key;
          const newStatusKey: string | undefined = state.states.find(
            (state) => state.id === action.payload.status.id
          )?.key;
          if (!currentStatusKey || !newStatusKey) {
            return { ...state };
          }
          return {
            ...state,
            loading: false,
            applicationLoading: false,
            applicationStatusCounter: {
              ...state.applicationStatusCounter,
              [currentStatusKey]: state.applicationStatusCounter[currentStatusKey] - 1,
              [newStatusKey]: state.applicationStatusCounter[newStatusKey] + 1,
            },
            applications: state.applications.filter(
              (application: RetrieveApplications.Application) =>
                application.id !== action.payload.id
            ),
            selectedApplication: {} as RetrieveApplication.Response,
          };
        }
      )
      .addCase(UPDATE_APPLICATION_STATUS.rejected, (state, _) => ({
        ...state,
        loading: false,
        applicationLoading: false,
      }))
      .addCase(
        SUBMIT_APPLICATION_RATING.fulfilled,
        (state, action: PayloadAction<SubmitApplicationRating.Response>) => {
          const application: RetrieveApplication.Response = { ...action.payload.application };
          const applications: RetrieveApplications.Application[] = [...state.applications];
          let selectedApplicationSkill: RetrieveApplication.Skill = {} as RetrieveApplication.Skill;
          let selectedQuestion: RetrieveApplication.Question = {} as RetrieveApplication.Question;
          for (const skill of application.soft_skills) {
            const match: RetrieveApplication.Question | undefined = skill.questions.find(
              (question: RetrieveApplication.Question) => question.id === action.payload.questionId
            );
            if (match) {
              selectedApplicationSkill = { ...skill };
              const question: RetrieveApplication.Question | undefined =
                selectedApplicationSkill.questions.find(
                  (question: RetrieveApplication.Question) =>
                    question.id === action.payload.questionId
                );
              if (question) {
                selectedQuestion = { ...question };
              }
            }
          }
          const listIndex: number = applications.findIndex(
            (app: RetrieveApplications.Application) => app.id === application.id
          );
          applications[listIndex] = {
            ...applications[listIndex],
            soft_skills_average: application.soft_skills_average,
            soft_skills_average_percentage: application.soft_skills_average_percentage,
            soft_skills_matching: application.soft_skills_matching,
            soft_skills_matching_percentage: application.soft_skills_matching_percentage,
            skills_matching_percentage: application.skills_matching_percentage,
          };
          return {
            ...state,
            selectedApplication: application,
            selectedApplicationSkill,
            selectedQuestion,
            applications,
          };
        }
      )
      .addCase(
        DELETE_APPLICATION_RATING.fulfilled,
        (state, action: PayloadAction<SubmitApplicationRating.Response>) => {
          const application: RetrieveApplication.Response = { ...action.payload.application };
          const applications: RetrieveApplications.Application[] = [...state.applications];
          let selectedApplicationSkill: RetrieveApplication.Skill = {} as RetrieveApplication.Skill;
          let selectedQuestion: RetrieveApplication.Question = {} as RetrieveApplication.Question;
          for (const skill of application.soft_skills) {
            const match: RetrieveApplication.Question | undefined = skill.questions.find(
              (question: RetrieveApplication.Question) => question.id === action.payload.questionId
            );
            if (match) {
              selectedApplicationSkill = { ...skill };
              const question: RetrieveApplication.Question | undefined =
                selectedApplicationSkill.questions.find(
                  (question: RetrieveApplication.Question) =>
                    question.id === action.payload.questionId
                );
              if (question) {
                selectedQuestion = { ...question };
              }
            }
          }
          const listIndex: number = applications.findIndex(
            (app: RetrieveApplications.Application) => app.id === application.id
          );
          applications[listIndex] = {
            ...applications[listIndex],
            soft_skills_average: application.soft_skills_average,
            soft_skills_average_percentage: application.soft_skills_average_percentage,
            soft_skills_matching: application.soft_skills_matching,
            soft_skills_matching_percentage: application.soft_skills_matching_percentage,
            skills_matching_percentage: application.skills_matching_percentage,
          };
          return {
            ...state,
            selectedApplication: application,
            selectedApplicationSkill,
            selectedQuestion,
            applications,
          };
        }
      )
      .addCase(RETRIEVE_JOB_POST_STATES.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        RETRIEVE_JOB_POST_STATES.fulfilled,
        (state, action: PayloadAction<RetrieveJobPostStates.Response>) => {
          // Sort states before saving them
          const states: ApplicationStatusDbModel[] = action.payload.states;
          states.sort((a, b) => b.global - a.global); //Always put global states before custom states

          /* Swap unsuitable status with the scored status.
           * Current order: applied, invited, scored, unsuitable
           * New order: applied, invited, unsuitable, scored
           *
           * REFACTOR: This should be done in the backend by creating a new column used for sorting
           */
          const unsuitableIndex: number = states.findIndex((state) => state.key === 'unsuitable');
          const scoredIndex: number = states.findIndex((state) => state.key === 'scored');
          [states[unsuitableIndex], states[scoredIndex]] = [
            states[scoredIndex],
            states[unsuitableIndex],
          ];

          return {
            ...state,
            loading: false,
            states,
          };
        }
      )
      .addCase(RETRIEVE_JOB_POST_STATES.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(SUBMIT_APPLICATION_STATE.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        SUBMIT_APPLICATION_STATE.fulfilled,
        (state, action: PayloadAction<ApplicationStatusDbModel>) => ({
          ...state,
          loading: false,
          states: [...state.states, action.payload],
          applicationStatusCounter: {
            ...state.applicationStatusCounter,
            [action.payload.key]: 0,
          },
        })
      )
      .addCase(SUBMIT_APPLICATION_STATE.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(UPDATE_APPLICATION_STATE.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        UPDATE_APPLICATION_STATE.fulfilled,
        (state, action: PayloadAction<ApplicationStatusDbModel>) => {
          const newStates: ApplicationStatusDbModel[] = [...state.states];
          const index: number = newStates.findIndex((state) => state.id === action.payload.id);
          if (!index) return state;
          const oldStateKey: string = newStates[index].key;
          const counters: RetrieveApplications.ApplicationStatusCounter = {
            ...state.applicationStatusCounter,
          };
          const counterValue: number = counters[oldStateKey];
          delete counters[oldStateKey];
          counters[action.payload.key] = counterValue;
          newStates[index] = {
            ...newStates[index],
            label: action.payload.label,
            key: action.payload.key,
          };
          return {
            ...state,
            loading: false,
            states: [...newStates],
            applicationStatusCounter: counters,
          };
        }
      )
      .addCase(UPDATE_APPLICATION_STATE.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(DELETE_APPLICATION_STATE.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(DELETE_APPLICATION_STATE.fulfilled, (state, action: PayloadAction<string>) => ({
        ...state,
        loading: false,
        states: state.states.filter(
          (state: ApplicationStatusDbModel) => state.id !== +action.payload
        ),
      }))
      .addCase(DELETE_APPLICATION_STATE.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(SINGLE_EMAIL_INVITE_CANDIDATE_TO_VIDEOINTERVIEW.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        SINGLE_EMAIL_INVITE_CANDIDATE_TO_VIDEOINTERVIEW.fulfilled,
        (state, action: PayloadAction<InviteCandidateToVideoInterview.Response>) => ({
          ...state,
          loading: false,
          applications: state.applications.filter(
            (application: RetrieveApplications.Application) =>
              application.id !== action.payload.applicationId
          ),
          selectedApplication: {} as RetrieveApplication.Response,
          applicationStatusCounter: {
            ...state.applicationStatusCounter,
            applied: state.applicationStatusCounter.applied - 1,
            invited: state.applicationStatusCounter.invited + 1,
          },
        })
      )
      .addCase(SINGLE_EMAIL_INVITE_CANDIDATE_TO_VIDEOINTERVIEW.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(SINGLE_SMS_INVITE_CANDIDATE_TO_VIDEOINTERVIEW.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        SINGLE_SMS_INVITE_CANDIDATE_TO_VIDEOINTERVIEW.fulfilled,
        (state, action: PayloadAction<InviteCandidateToVideoInterview.Response>) => ({
          ...state,
          loading: false,
          applications: state.applications.filter(
            (application: RetrieveApplications.Application) =>
              application.id !== action.payload.applicationId
          ),
          selectedApplication: {} as RetrieveApplication.Response,
          applicationStatusCounter: {
            ...state.applicationStatusCounter,
            applied: state.applicationStatusCounter.applied - 1,
            invited: state.applicationStatusCounter.invited + 1,
          },
        })
      )
      .addCase(SINGLE_SMS_INVITE_CANDIDATE_TO_VIDEOINTERVIEW.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(BULK_EMAIL_INVITE_TO_VIDEOINTERVIEW.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(BULK_EMAIL_INVITE_TO_VIDEOINTERVIEW.fulfilled, (state, _) => ({
        ...state,
        loading: false,
        applications: [],
        selectedApplication: {} as RetrieveApplication.Response,
        applicationStatusCounter: {
          ...state.applicationStatusCounter,
          applied: state.applicationStatusCounter.applied - state.applications.length,
          invited: state.applicationStatusCounter.invited + state.applications.length,
        },
      }))
      .addCase(BULK_EMAIL_INVITE_TO_VIDEOINTERVIEW.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(BULK_SMS_INVITE_TO_VIDEOINTERVIEW.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(BULK_SMS_INVITE_TO_VIDEOINTERVIEW.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        applications: state.applications.filter((a) => !a.mobile_number),
        selectedApplication: {} as RetrieveApplication.Response,
        applicationStatusCounter: {
          ...state.applicationStatusCounter,
          applied:
            state.applicationStatusCounter.applied -
            state.applications.filter((a) => !!a.mobile_number).length,
          invited:
            state.applicationStatusCounter.invited +
            state.applications.filter((a) => !!a.mobile_number).length,
        },
        notInvitedBySms: {
          applications: state.applications.filter((a) => !a.mobile_number),
          totalApplied: action.payload.totalCounter,
          totalInvited: action.payload.successCounter,
        },
      }))
      .addCase(BULK_SMS_INVITE_TO_VIDEOINTERVIEW.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(resetNotInvitedBySms, (state, _) => ({
        ...state,
        notInvitedBySms: null,
      }))
      .addCase(BULK_APPLICATIONS_PHONE_UPDATE.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(BULK_APPLICATIONS_PHONE_UPDATE.fulfilled, (state, _) => ({
        ...state,
        loading: false,
        notInvitedBySms: null,
      }))
      .addCase(BULK_APPLICATIONS_PHONE_UPDATE.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(SUBMIT_CV_RATING.pending, (state, _) => ({
        ...state,
      }))
      .addCase(
        SUBMIT_CV_RATING.fulfilled,
        (state, action: PayloadAction<SubmitCvRating.Response>) => {
          const applications: RetrieveApplications.Application[] = state.applications.map(
            (application: RetrieveApplications.Application) => {
              return application.id === action.payload.applicationId
                ? {
                    ...application,
                    cv_rating: action.payload.rating.toString(),
                  }
                : { ...application };
            }
          );
          return {
            ...state,
            applications,
            selectedApplication: state.selectedApplication
              ? {
                  ...state.selectedApplication,
                  cv_rating: action.payload.rating.toString(),
                }
              : ({} as RetrieveApplication.Response),
          };
        }
      )
      .addCase(SUBMIT_CV_RATING.rejected, (state, _) => ({
        ...state,
      }))
      .addCase(SUBMIT_CV_UPLOAD.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        SUBMIT_CV_UPLOAD.fulfilled,
        (state, action: PayloadAction<SubmitCvUpload.Response>) => {
          return {
            ...state,
            loading: false,
            applications: state.applications.map((application: RetrieveApplications.Application) =>
              application.id === action.payload.applicationId
                ? { ...application, cv: action.payload.cv }
                : { ...application }
            ),
            selectedApplication: state.selectedApplication
              ? {
                  ...state.selectedApplication,
                  cv: action.payload.cv,
                }
              : ({} as RetrieveApplication.Response),
          };
        }
      )
      .addCase(SUBMIT_CV_UPLOAD.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(selectApplicationSkill, (state, action: PayloadAction<number | null>) => {
        const softSkill: RetrieveApplication.Skill | undefined =
          Object.keys(state.selectedApplication).length > 0
            ? state.selectedApplication.soft_skills.find(
                (skill: RetrieveApplication.Skill) => skill.id === action.payload
              )
            : undefined;
        if (softSkill) {
          const questionsWithInterview: RetrieveApplication.Question[] = softSkill.questions.filter(
            (question: RetrieveApplication.Question) => question.interview !== null
          );
          return {
            ...state,
            selectedApplicationSkill: softSkill,
            selectedQuestion:
              questionsWithInterview.length > 0
                ? questionsWithInterview[0]
                : ({} as RetrieveApplication.Question),
          };
        } else {
          return {
            ...state,
            selectedApplicationSkill: {} as RetrieveApplication.Skill,
            selectedQuestion: {} as RetrieveApplication.Question,
          };
        }
      })
      .addCase(selectApplicationQuestion, (state, action: PayloadAction<number>) => {
        const question: RetrieveApplication.Question | undefined =
          state.selectedApplicationSkill?.questions.find(
            (question: RetrieveApplication.Question) => question.id === action.payload
          );
        if (!question) {
          return {
            ...state,
          };
        }
        return {
          ...state,
          selectedQuestion: {
            ...question,
          },
        };
      })
      // Auth Slice
      .addCase(changeCompany, (state, _) => ({
        ...state,
        selectedJob: {} as RetrieveApplications.Job,
      }))
      // JobPost Slice
      .addCase(
        UPDATE_JOB_POST_STATUS.fulfilled,
        (state, action: PayloadAction<UpdateJobPostStatusResponse>) => ({
          ...state,
          loading: false,
          selectedJob: {
            ...action.payload.job,
          },
        })
      )
      .addCase(UPDATE_APPLICATION.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        UPDATE_APPLICATION.fulfilled,
        (state, action: PayloadAction<RetrieveApplication.Response>) => {
          let applications: RetrieveApplications.Application[] = [...state.applications];
          let applicationStatusCounter = { ...state.applicationStatusCounter };
          const updatedApplication: RetrieveApplications.Application | undefined =
            applications.find(
              (application: RetrieveApplications.Application) =>
                application.id === action.payload.id
            );
          if (!updatedApplication) return state;
          const oldStatusId: number = updatedApplication.status_id;
          const newStatusId: number = action.payload.status.id;
          applications = applications.map((application: RetrieveApplications.Application) => ({
            ...application,
            name: application.id === action.payload.id ? action.payload.name : application.name,
            surname:
              application.id === action.payload.id ? action.payload.surname : application.surname,
            email: application.id === action.payload.id ? action.payload.email : application.email,
            mobile_number:
              application.id === action.payload.id
                ? action.payload.mobile_number
                : application.mobile_number,
            status_id:
              application.id === action.payload.id
                ? action.payload.status.id
                : application.status_id,
          }));
          if (newStatusId !== oldStatusId) {
            applications = applications.filter(
              (application: RetrieveApplications.Application) =>
                application.status_id === oldStatusId
            );
            const oldStatus: ApplicationStatusDbModel | undefined = state.states.find(
              (s: ApplicationStatusDbModel) => s.id === oldStatusId
            );
            const newStatus: ApplicationStatusDbModel | undefined = state.states.find(
              (s: ApplicationStatusDbModel) => s.id === newStatusId
            );
            if (oldStatus && newStatus) {
              applicationStatusCounter = {
                ...applicationStatusCounter,
                [oldStatus.key]: applicationStatusCounter[oldStatus.key] - 1,
                [newStatus.key]: applicationStatusCounter[newStatus.key] + 1,
              };
            }
          }
          return {
            ...state,
            loading: false,
            applications,
            selectedApplication: {
              ...action.payload,
            },
            applicationStatusCounter,
          };
        }
      )
      .addCase(UPDATE_APPLICATION.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(DELETE_APPLICATION.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(DELETE_APPLICATION.fulfilled, (state, action: PayloadAction<number>) => {
        let applicationStatusCounter: RetrieveApplications.ApplicationStatusCounter = {
          ...state.applicationStatusCounter,
        };
        const application: RetrieveApplications.Application | undefined = state.applications.find(
          (application: RetrieveApplications.Application) => application.id === action.payload
        );
        if (application) {
          const status: ApplicationStatusDbModel | undefined = state.states.find(
            (s: ApplicationStatusDbModel) => s.id === application.status_id
          );
          if (status) {
            applicationStatusCounter = {
              ...applicationStatusCounter,
              [status.key]: applicationStatusCounter[status.key] - 1,
            };
          }
        }
        return {
          ...state,
          loading: false,
          applications: state.applications.filter(
            (application: RetrieveApplications.Application) => application.id !== action.payload
          ),
          applicationStatusCounter,
          selectedApplication: {} as RetrieveApplication.Response,
        };
      })
      .addCase(DELETE_APPLICATION.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(
        EDIT_RECRUITMENT.fulfilled,
        (state, action: PayloadAction<RetrieveApplications.Job>) => ({
          ...state,
          selectedJob: { ...action.payload },
        })
      )
      .addCase(resetSelectedJob, (state, _) => ({
        ...state,
        selectedJob: {} as RetrieveApplications.Job,
        selectedApplication: {} as RetrieveApplication.Response,
        selectedApplicationSkill: {} as RetrieveApplication.Skill,
        selectedQuestion: {} as RetrieveApplication.Question,
      }))
      .addCase(resetSelectedApplication, (state, _) => ({
        ...state,
        selectedApplication: {} as RetrieveApplication.Response,
      }))
      .addCase(EXPORT_CANDIDATES_TO_EXCEL.pending, (state, _) => ({
        ...state,
        exportCandidatesLoading: true,
      }))
      .addCase(EXPORT_CANDIDATES_TO_EXCEL.fulfilled, (state, _) => ({
        ...state,
        exportCandidatesLoading: false,
      }))
      .addCase(EXPORT_CANDIDATES_TO_EXCEL.rejected, (state, _) => ({
        ...state,
        exportCandidatesLoading: false,
      }))
      .addCase(
        REQUEST_CANDIDATE_REPORT.fulfilled,
        (state, action: PayloadAction<RetrieveApplication.Reports>) => ({
          ...state,
          selectedApplication: {
            ...state.selectedApplication,
            reports: action.payload,
          },
        })
      )
      .addCase(DOWNLOAD_CANDIDATE_REPORT_LIGHT.pending, (state, _) => ({
        ...state,
        downloadReportLightLoading: true,
      }))
      .addCase(DOWNLOAD_CANDIDATE_REPORT_LIGHT.fulfilled, (state, _) => ({
        ...state,
        downloadReportLightLoading: false,
      }))
      .addCase(DOWNLOAD_CANDIDATE_REPORT_LIGHT.rejected, (state, _) => ({
        ...state,
        downloadReportLightLoading: false,
      }))
      .addCase(DOWNLOAD_CANDIDATE_REPORT_PRO.pending, (state, _) => ({
        ...state,
        downloadReportProLoading: true,
      }))
      .addCase(DOWNLOAD_CANDIDATE_REPORT_PRO.fulfilled, (state, _) => ({
        ...state,
        downloadReportProLoading: false,
      }))
      .addCase(DOWNLOAD_CANDIDATE_REPORT_PRO.rejected, (state, _) => ({
        ...state,
        downloadReportProLoading: false,
      }))
      .addCase(SUBMIT_PRESENTATION_RATING.pending, (state, _) => ({
        ...state,
      }))
      .addCase(
        SUBMIT_PRESENTATION_RATING.fulfilled,
        (state, action: PayloadAction<SubmitPresentationRatingResponse>) => {
          const applications: RetrieveApplications.Application[] = state.applications.map(
            (application: RetrieveApplications.Application) =>
              application.id === action.payload.applicationId
                ? { ...application, presentation_interview_rating: action.payload.rating }
                : application
          );

          return {
            ...state,
            applications,
            selectedApplication: state.selectedApplication && {
              ...state.selectedApplication,
              presentation_interview: state.selectedApplication.presentation_interview && {
                ...state.selectedApplication.presentation_interview,
                rating: action.payload.rating,
              },
            },
          };
        }
      )
      .addCase(SUBMIT_PRESENTATION_RATING.rejected, (state, _) => ({
        ...state,
      }))
      .addCase(SUBMIT_EMAIL_FEEDBACK.pending, (state, _) => ({ ...state }))
      .addCase(
        SUBMIT_EMAIL_FEEDBACK.fulfilled,
        (state, action: PayloadAction<SubmitEmailFeedbackResponse | null>) => {
          let applications: RetrieveApplications.Application[] = state.applications;
          if (action.payload) {
            applications = state.applications.map(
              (application: RetrieveApplications.Application) =>
                application.id === action.payload?.applicationId
                  ? {
                      ...application,
                      email_feedback: {
                        ...action.payload,
                        sent_at: new Date().toISOString(),
                      },
                    }
                  : application
            );
          }
          return {
            ...state,
            applications,
            selectedApplication: {
              ...state.selectedApplication,
              email_feedback: {
                ...action.payload,
                sent_at: new Date().toISOString(),
              } as SubmitEmailFeedbackResponse,
            },
          };
        }
      )
      .addCase(SUBMIT_EMAIL_FEEDBACK.rejected, (state, _) => ({ ...state }))
      .addCase(
        selectApplicationCustomQuestionnaire,
        (state, action: PayloadAction<number | null>) => ({
          ...state,
          selectedCustomQuestionnaire:
            state.selectedApplication.custom_questionnaires?.find(
              (cq) => cq.id === action.payload
            ) || null,
        })
      ),
});

export default slice.reducer;
